import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import SolutionContainer from '../containers/solutionContainer';
import { graphql, useStaticQuery } from 'gatsby';

const MosquitoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      faq: allContentfulFaq {
        edges {
          node {
            answear {
              answear
            }
            question
            id
          }
        }
      }
      mosquito: file(relativePath: { eq: "mosquito_image.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Layout defaultColor={({ theme }) => theme.colors.lightBg} fixed={false}>
      <SEO
        title="Mosquito"
        description="One of our professional exterminating experts will come to your home or office in the greater St. Louis area, and solve all your mosquito problems at a time that is convenient for you!  Simply choose an available date and time from the calendar below and request a Free Inspection."
      />
      <SolutionContainer
        caption="our solutions"
        title="Mosquito Control, St. Louis"
        subTitleOne="Free mosquito Inspection"
        textOne="If you would like us to send a certified inspector to assess and inspect your home or business, FREE OF CHARGE, give us a call right away at (314) 558 - 4847, or book an online appointment and choose a time that is best for you! "
        subTitleTwo="Some Questions and Answers"
        image={data.mosquito.childImageSharp.fluid}
        faq={data.faq.edges}
      />
    </Layout>
  );
};
export default MosquitoPage;
